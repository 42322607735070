/* Login Stylesheet */
@import "common/_variables";

.login h1 a{
	background-image:url('#{$img-path}logo@2x.png');
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: 50% 50%;
	width:100%;
	height:150px;
	text-indent:-9999px;
	overflow:hidden;
	padding-bottom:15px;
	display:block;
}
